import gql from 'graphql-tag';

export const CommentButtonQuery = gql`
	query CommentButtonQuery($pageId: ID!) {
		comments(pageId: $pageId, type: UNRESOLVED, depth: ALL) {
			count
			nodes {
				id
				location {
					type
					... on InlineComment {
						inlineMarkerRef
					}
				}
			}
		}
	}
`;

import { LoadableLazy } from '@confluence/loadable';

export type { PageUrlAutomationMenuProps } from '../src/PageUrlAutomationMenu';
export const PageUrlAutomationMenu = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageQuickActionAutomationMenu" */ '../src/PageUrlAutomationMenu'
			)
		).PageUrlAutomationMenu,
});

import React from 'react';
import PropTypes from 'prop-types';

import { LoadablePaint, LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';

import { WATCH_BUTTON_METRIC } from './perf.config';
import { WatchButtonPlaceholder } from './WatchButtonPlaceholder';

export { WatchButtonPlaceholder } from './WatchButtonPlaceholder';
export { WATCH_DIALOG_LOCATION } from './WatchDialogLocation';
export { WatchContentNudgeSpotlight } from './WatchContentNudgeSpotlight';

export const LazyDialogWrapperLoader = LoadableLazy<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-watch-dialog" */ './WatchDialogWrapper'))
			.WatchDialogWrapper,
	loading: WatchButtonPlaceholder,
});

export const WatchDialog = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-watch-dialog" */ './WatchDialog')).WatchDialog,
	loading: WatchButtonPlaceholder,
});

export const WatchDialogWrapperLoader = LoadablePaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-watch-dialog" */ './WatchDialogWrapper'))
			.WatchDialogWrapper,
	loading: WatchButtonPlaceholder,
});

export const WatchDialogWrapper = (props) => {
	useSubmitSSRScriptErrors('page-watch-button');
	return (
		<>
			<PageSegmentLoadStart key={props.contentId} metric={WATCH_BUTTON_METRIC} />
			<WatchDialogWrapperLoader {...props} />
		</>
	);
};

WatchDialogWrapper.propTypes = {
	contentId: PropTypes.string,
	defaultTab: PropTypes.number,
	componentLocation: PropTypes.number,
};

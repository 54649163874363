import React from 'react';

import { ConfluencePageAri } from '@atlassian/ari';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

import { AutomationPopup } from './automation-popup/AutomationPopup';
import { AutomationGroupedPageButton } from './automation-popup/triggers/AutomationGroupedPageButton';
import type { PageGroupedButtonsAutomationMenuProps } from './types';
import { useAutomationUrlParamTrigger } from './automation-url-param-trigger/AutomationUrlParamTrigger';

export const PageGroupedButtonsAutomationMenu = (props: PageGroupedButtonsAutomationMenuProps) => {
	const { pageId } = props;

	const { cloudId } = useSessionData();
	const pageAri = ConfluencePageAri.create({ siteId: cloudId, pageId });

	// If we have relevant url arguments, automatically show PageUrlAutomationMenu
	useAutomationUrlParamTrigger();

	// Get the state telling if the current page is live-edit page or not
	const isLivePage = useIsLivePage();

	return (
		<ErrorBoundary attribution={Attribution.AUTOMATION_FOR_CONFLUENCE}>
			<AutomationPopup
				{...props}
				aris={[pageAri]}
				analyticsSource="pageGroupedButtonsManualTriggers"
				Trigger={AutomationGroupedPageButton}
				customAnalyticsAttributes={{ isLivePage }}
			/>
		</ErrorBoundary>
	);
};

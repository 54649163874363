import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import { EventSourceName } from './utils/analytics';
import SmartButtonSpotlightHeader from './assets/SmartButtonSpotlightHeader.png';

const i18n = defineMessages({
	title: {
		id: 'automation-discovery.automation-menu-smart-button-spotlight.title',
		defaultMessage: 'Smart buttons are here',
		description: 'Title shown in a spotlight popup',
	},
	body: {
		id: 'automation-discovery.automation-menu-smart-button-spotlight.body',
		defaultMessage:
			'Smart buttons streamline complex workflows. Use automation rules to enhance efficiency and give anyone the power to trigger complex actions.',
		description: 'Body text shown in a spotlight popup',
	},
	linkToBlog: {
		id: 'automation-discovery.automation-menu-smart-button-spotlight.link-to-blog',
		defaultMessage: 'Learn more about smart buttons',
		description: 'Link to blog post about smart buttons',
	},
	dismiss: {
		id: 'automation-discovery.automation-menu-smart-button-spotlight.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button text',
	},
	cta: {
		id: 'automation-discovery.automation-menu-smart-button-spotlight.cta',
		defaultMessage: 'Show me how',
		description: 'Proceed button text which links the user to a blog post',
	},
});

interface AutomationMenuSmartButtonSpotlightProps {
	handleDismiss: () => void;
	handleProceed: () => void;
}
export const AutomationMenuSmartButtonSpotlight = ({
	handleDismiss,
	handleProceed,
}: AutomationMenuSmartButtonSpotlightProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { Spotlight } = useChoreographerAssets();

	// Fire analytics event when spotlight is viewed
	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				action: 'viewed',
				name: EventSourceName.MANUAL_TRIGGER_SMART_BUTTON,
			},
		}).fire();
	}, [createAnalyticsEvent]);

	return (
		<Spotlight
			dialogPlacement="bottom right"
			image={SmartButtonSpotlightHeader}
			actions={[
				{
					onClick: handleProceed,
					text: formatMessage(i18n.cta),
				},
				{
					onClick: handleDismiss,
					text: formatMessage(i18n.dismiss),
				},
			]}
			heading={formatMessage(i18n.title)}
			targetRadius={2}
			target="automation-manual-trigger-menu"
			key="automation-manual-trigger-menu"
			dialogWidth={275}
			messageType="engagement"
			messageId="automation-menu-smart-button-spotlight"
		>
			{formatMessage(i18n.body)}
		</Spotlight>
	);
};

import React, { forwardRef, useContext, useEffect, useState } from 'react';
import memoize from 'memoize-one';
import { useCoordination } from '@atlassiansox/engagekit-ts';

import { SpotlightTarget, SpotlightPulse, SpotlightTransition } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ExperienceFailure,
	AUTOMATION_DISCOVERY_MANUAL_TRIGGER,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import {
	THIRTY_DAYS_IN_MILLISECONDS,
	MANUAL_TRIGGER_BUTTON_PULSE_KEY,
	EventSourceName,
} from '@confluence/automation-discovery/entry-points/constants';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { AutomationMenuSpotlight } from '@confluence/automation-discovery/entry-points';

import { useAutomationPermissionService } from '../../permission-service';
import { useAutomationMenu } from '../../common/ConfluenceManualRulesContainer';

import type { AutomationGroupedPageButtonProps } from './AutomationGroupedPageButton';
import { AutomationMenuButton } from './AutomationMenuButton';

export const getShouldShowManualTriggerSpotlight = memoize((currentRuleCount: number): boolean => {
	const ruleCountData: { ruleCount: number; timestamp: number } | null = localStorage.getItem(
		keys.AUTOMATION_DISCOVER_MANUAL_RULE_COUNT,
	);

	// In case the user ignores the message, we set the current rule count to check later
	if (!ruleCountData) {
		localStorage.setItem(keys.AUTOMATION_DISCOVER_MANUAL_RULE_COUNT, {
			ruleCount: currentRuleCount,
			timestamp: Date.now(),
		});

		return true;
	}

	const { ruleCount: prevRuleCount, timestamp } = ruleCountData!;

	// We do a comparison of the user's rules every 30 days to determine if they have created any new rules
	// Here not enough time has passed, so we do not show the spotlight
	if (Date.now() - timestamp < THIRTY_DAYS_IN_MILLISECONDS) {
		return false;
	}

	// If the rule count has decreased or remained the same, we assume the user has not created any new rules
	// Therefore, we show the spotlight to them
	// There is a possibility that the user has created and deleted the same number of rules,
	// but we decided to accept this edge case.
	return currentRuleCount <= prevRuleCount;
});

export const setUserHasSeenManualTriggerSpotlight = (): void => {
	localStorage.setItem(keys.AUTOMATION_DISCOVER_MANUAL_TRIGGER, true);
};

export const hasUserSeenManualTriggerSpotlight = (): boolean => {
	return localStorage.getItemAsBoolean(keys.AUTOMATION_DISCOVER_MANUAL_TRIGGER);
};

type ConditionalWrapperChildren = React.PropsWithChildren<any> | null;
const ConditionalWrapper = ({
	condition,
	wrapper,
	children,
}: {
	condition: boolean;
	wrapper: (children: ConditionalWrapperChildren) => ConditionalWrapperChildren;
	children: ConditionalWrapperChildren;
}) => (condition ? wrapper(children) : children);

interface AutomationMenuButtonWithSpotlightProps {
	onClickWithAnalytics: () => void;
}

export const AutomationMenuButtonWithSpotlight = forwardRef<
	HTMLElement,
	AutomationGroupedPageButtonProps & AutomationMenuButtonWithSpotlightProps
>(
	(
		{
			isSelected,
			onClickWithAnalytics,
		}: AutomationGroupedPageButtonProps & AutomationMenuButtonWithSpotlightProps,
		ref,
	) => {
		const [isSpotlightActive, setIsSpotlightActive] = useState(false);
		const [shouldShowPulse, setShouldShowPulse] = useState(false);
		const coordinationClient = useCoordinationClient();
		const [pulseStarted, stopPulse] = useCoordination(
			coordinationClient,
			MANUAL_TRIGGER_BUTTON_PULSE_KEY,
		);
		const { rules, spaceKey } = useAutomationMenu();
		const { canManageAutomations, error: permissionServiceError } =
			useAutomationPermissionService(spaceKey);
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const experienceTracker = useContext(ExperienceTrackerContext);

		useEffect(() => {
			experienceTracker.start({
				name: AUTOMATION_DISCOVERY_MANUAL_TRIGGER,
			});
		}, [experienceTracker]);

		const ruleCount = rules.length;

		const handleProceed = () => {
			setIsSpotlightActive(false);
			if (pulseStarted) {
				void stopPulse();
			}
			setShouldShowPulse(false);
			window.open(
				`/wiki/spaces/${spaceKey}/settings/automation#/rule/template?templateId=confluence_cross_product_template_7`,
				'_blank',
			);
			setUserHasSeenManualTriggerSpotlight();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'automationDiscoveryManualTriggerProceed',
					source: EventSourceName.MANUAL_TRIGGER,
					attributes: {
						spaceKey,
					},
				},
			}).fire();
		};

		const handleDismiss = () => {
			setIsSpotlightActive(false);
			if (pulseStarted) {
				void stopPulse();
			}
			setShouldShowPulse(false);
			setUserHasSeenManualTriggerSpotlight();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'automationDiscoveryManualTriggerDismiss',
					source: EventSourceName.MANUAL_TRIGGER,
					attributes: {
						spaceKey,
					},
				},
			}).fire();
		};

		const showSpotlight = () => {
			setIsSpotlightActive(true);
		};

		useEffect(() => {
			if (
				canManageAutomations &&
				!permissionServiceError &&
				!hasUserSeenManualTriggerSpotlight() &&
				getShouldShowManualTriggerSpotlight(ruleCount) &&
				pulseStarted
			) {
				setShouldShowPulse(true);
				experienceTracker.succeed({
					name: AUTOMATION_DISCOVERY_MANUAL_TRIGGER,
				});
			}
		}, [canManageAutomations, permissionServiceError, pulseStarted, experienceTracker, ruleCount]);

		return (
			<ConditionalWrapper
				condition={shouldShowPulse}
				wrapper={(children) => (
					<>
						<SpotlightTarget name="automation-manual-trigger-menu">
							<SpotlightPulse radius={3}>{children}</SpotlightPulse>
						</SpotlightTarget>
						<SpotlightTransition>
							{isSpotlightActive && (
								<AutomationMenuSpotlight
									handleDismiss={handleDismiss}
									handleProceed={handleProceed}
								/>
							)}
						</SpotlightTransition>
					</>
				)}
			>
				<AutomationMenuButton
					ref={ref}
					isSelected={isSelected}
					onClickWithAnalytics={onClickWithAnalytics}
					shouldShowPulse={shouldShowPulse}
					showSpotlight={showSpotlight}
				/>
				{permissionServiceError && (
					<ErrorDisplay error={permissionServiceError}>
						<ExperienceFailure
							name={AUTOMATION_DISCOVERY_MANUAL_TRIGGER}
							error={permissionServiceError}
						/>
					</ErrorDisplay>
				)}
			</ConditionalWrapper>
		);
	},
);

import { LoadablePaint, LoadableLazy } from '@confluence/loadable';

import { FavouriteButtonPlaceholder } from './FavouriteButtonPlaceholder';

export const FavouriteButton = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-FavouriteButton" */ './FavouriteButton'))
			.FavouriteButton,
	loading: FavouriteButtonPlaceholder,
});

export const LazyFavouriteButton = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-FavouriteButton" */ './FavouriteButton'))
			.FavouriteButton,
	loading: FavouriteButtonPlaceholder,
});

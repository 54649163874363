import { useEffect, useState } from 'react';

import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';

import { PageUrlAutomationMenu } from '../../entry-points/PageUrlAutomationMenu';

import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';

import { createSingleQueryParamHook } from '@confluence/route-manager';

const useRunAutomationIdQueryParam = createSingleQueryParamHook('runAutomationId');

export const useAutomationUrlParamTrigger = () => {
	const { showModal } = useDialogs();

	// These return 'undefined' from within modals for some reason, so have to get them here instead of inside PageUrlAutomationMenu
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();

	// Need to get this here to know if we should call showModal() or not
	const runAutomationIdQueryParam = useRunAutomationIdQueryParam();

	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const shouldShowModal = runAutomationIdQueryParam && spaceKey && contentId && isFirstLoad;

	useEffect(() => {
		if (shouldShowModal) {
			// We only want show the modal once on page load
			setIsFirstLoad(false);

			showModal(PageUrlAutomationMenu, {
				spaceKey,
				pageId: contentId,
				preselectedRuleId: parseInt(runAutomationIdQueryParam),
			});
		}
	}, [spaceKey, contentId, shouldShowModal, runAutomationIdQueryParam, showModal]);
};

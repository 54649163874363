import React, { useContext, useState } from 'react';
import type { ComponentType } from 'react';

import Popup from '@atlaskit/popup';

import type { Ari } from '@atlassian/ari';

import {
	AUTOMATION_MANUAL_TRIGGERS,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { LoadableLazy } from '@confluence/loadable';

import { ConfluenceManualRulesContainer } from '../common/ConfluenceManualRulesContainer';
import type { TriggerProps } from '../types';

const LazyAutomationPopupContent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AutomationPopupContent" */ './AutomationPopupContent'
			)
		).AutomationPopupContent,
});

type AutomationPopupProps = {
	aris: Ari[];
	analyticsSource: string;
	spaceKey: string;
	Trigger: ComponentType<TriggerProps>;
	customAnalyticsAttributes: { [key: string]: any };
};

export const AutomationPopup = ({
	Trigger,
	aris,
	analyticsSource,
	spaceKey,
	customAnalyticsAttributes,
}: AutomationPopupProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);

	return (
		<ConfluenceManualRulesContainer
			aris={aris}
			spaceKey={spaceKey}
			analyticsSource={analyticsSource}
			singleSpace
		>
			{() => {
				return (
					<Popup
						isOpen={isOpen}
						onClose={() => {
							setIsOpen(false);
						}}
						placement="bottom-end"
						shouldRenderToParent
						content={() => <LazyAutomationPopupContent />}
						trigger={({ ...triggerProps }) => (
							<Trigger
								{...triggerProps}
								onClick={() => {
									experienceTracker.start({
										name: AUTOMATION_MANUAL_TRIGGERS,
										attributes: {
											source: analyticsSource,
											...customAnalyticsAttributes,
										},
									});
									setIsOpen(!isOpen);
								}}
								isSelected={isOpen}
							/>
						)}
					/>
				);
			}}
		</ConfluenceManualRulesContainer>
	);
};

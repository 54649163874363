import React, { useEffect } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import { EventSourceName } from './utils/analytics';
import { commonI18n, manualTriggerI18n } from './i18n';
import AutomationDiscoveryHeader from './assets/AutomationDiscoveryHeader.svg';

interface AutomationMenuSpotlightProps {
	handleDismiss: () => void;
	handleProceed: () => void;
}
export const AutomationMenuSpotlight = ({
	handleDismiss,
	handleProceed,
}: AutomationMenuSpotlightProps) => {
	const { Spotlight } = useChoreographerAssets();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Fire analytics event when spotlight is viewed
	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				action: 'viewed',
				name: EventSourceName.MANUAL_TRIGGER,
			},
		}).fire();
	}, [createAnalyticsEvent]);

	return (
		<Spotlight
			dialogPlacement="bottom right"
			image={AutomationDiscoveryHeader}
			actions={[
				{
					onClick: handleDismiss,
					text: formatMessage(commonI18n.dismiss),
				},
				{
					onClick: handleProceed,
					text: formatMessage(commonI18n.spotlightCta),
				},
			]}
			heading={formatMessage(manualTriggerI18n.title)}
			targetRadius={2}
			target="automation-manual-trigger-menu"
			key="automation-manual-trigger-menu"
			dialogWidth={275}
			messageType="transactional"
		>
			{formatMessage(manualTriggerI18n.body)}
		</Spotlight>
	);
};

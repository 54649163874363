import React, { forwardRef, useState } from 'react';

import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import { EventSourceName } from '@confluence/automation-discovery/entry-points/constants';
import { AutomationMenuSmartButtonSpotlight } from '@confluence/automation-discovery/entry-points';

import { useAutomationMenu } from '../../common/ConfluenceManualRulesContainer';

import type { AutomationGroupedPageButtonProps } from './AutomationGroupedPageButton';
import { AutomationMenuButton } from './AutomationMenuButton';

export const getIsEligibleForSpotlight = (): boolean => {
	const hasSeenSmartButtonChangeboarding = localStorage.getItem(
		keys.AUTOMTION_DISCOVER_MANUAL_RULE_SMART_BUTTON,
	);
	const hasSetPageLabel = localStorage.getItem(keys.AUTOMATION_DISCOVER_ADD_LABEL_TO_PAGE);
	const hasPublishedMeetingNotes = localStorage.getItem(
		keys.AUTOMATION_DISCOVER_MEETING_NOTES_TEMPLATE,
	);
	const hasSetContentState = localStorage.getItem(keys.AUTOMATION_DISCOVER_SET_CONTENT_STATE);

	return (
		!hasSeenSmartButtonChangeboarding &&
		(hasSetPageLabel || hasPublishedMeetingNotes || hasSetContentState)
	);
};

export const setUserHasSeenSpotlight = (): void => {
	localStorage.setItem(keys.AUTOMTION_DISCOVER_MANUAL_RULE_SMART_BUTTON, true);
};

interface AutomationMenuButtonWithSmartButtonSpotlightProps {
	onClickWithAnalytics: () => void;
}

export const AutomationMenuButtonWithSmartButtonSpotlight = forwardRef<
	HTMLElement,
	AutomationGroupedPageButtonProps & AutomationMenuButtonWithSmartButtonSpotlightProps
>(
	(
		{
			isSelected,
			onClickWithAnalytics,
		}: AutomationGroupedPageButtonProps & AutomationMenuButtonWithSmartButtonSpotlightProps,
		ref,
	) => {
		const [isSpotlightActive, setIsSpotlightActive] = useState(true);
		const { spaceKey } = useAutomationMenu();

		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleProceed = () => {
			setIsSpotlightActive(false);
			window.open(
				`https://community.atlassian.com/t5/Confluence-articles/End-Users-Can-Now-Easily-Leverage-Automation-for-Confluence-with/ba-p/2747531`,
				'_blank',
			);
			setUserHasSeenSpotlight();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'automationDiscoveryManualTriggerSmartButtonProceed',
					source: EventSourceName.MANUAL_TRIGGER_SMART_BUTTON,
					attributes: {
						spaceKey,
					},
				},
			}).fire();
		};

		const handleDismiss = () => {
			setIsSpotlightActive(false);
			setUserHasSeenSpotlight();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'automationDiscoveryManualTriggerSmartButtonDismiss',
					source: EventSourceName.MANUAL_TRIGGER_SMART_BUTTON,
					attributes: {
						spaceKey,
					},
				},
			}).fire();
		};

		return getIsEligibleForSpotlight() ? (
			<>
				<SpotlightTarget name="automation-manual-trigger-menu">
					<AutomationMenuButton
						ref={ref}
						isSelected={isSelected}
						onClickWithAnalytics={onClickWithAnalytics}
					/>
				</SpotlightTarget>
				<SpotlightTransition>
					{isSpotlightActive && (
						<AutomationMenuSmartButtonSpotlight
							handleDismiss={handleDismiss}
							handleProceed={handleProceed}
						/>
					)}
				</SpotlightTransition>
			</>
		) : (
			<AutomationMenuButton
				ref={ref}
				isSelected={isSelected}
				onClickWithAnalytics={onClickWithAnalytics}
			/>
		);
	},
);

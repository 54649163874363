import React from 'react';

// TODO: https://hello.jira.atlassian.cloud/browse/A4C-3424 Replace with @atlaskit/icon/core/automation
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/no-custom-icons */
import Icon from '@atlaskit/icon';

export const AutomationGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14 5L10 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
		<path
			d="M14 5L7 12H17L10 19"
			stroke="currentColor"
			fill="transparent"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const AutomationIcon = () => {
	return <Icon glyph={AutomationGlyph} label="Automation" />;
};

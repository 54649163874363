import React from 'react';

import {
	VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import type { RestrictionsDialogTriggerProps } from '../types';

import { RestrictionsDialogTrigger } from './RestrictionsDialogTrigger';

export const RestrictionsDialogTriggerWrapper = (props: RestrictionsDialogTriggerProps) => {
	const { accessStatus } = useSessionData();

	const hasAccess =
		accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

	return (
		<ErrorBoundary
			attribution={Attribution.PERMISSIONS_EXPERIENCE}
			key="restrictions-dialog-trigger"
		>
			{hasAccess ? (
				<>
					<ExperienceStart
						key="restriction-button-start"
						id={props.contentId}
						name={VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE}
					/>
					<RestrictionsDialogTrigger {...props} />
				</>
			) : null}
		</ErrorBoundary>
	);
};
